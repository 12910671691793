import * as React from 'react';
import Campaigns from './screens/Campaigning/Campaigns';
import CampaignsNew from './screens/CampaigningNew/CampaignsNew';
import CreateCampaign from './screens/Campaigning/CreateCampaign';
import EditCampaignOld from './screens/Campaigning/EditCampaign';
import EditCampaignNew from './screens/CampaigningNew/EditCampaignNew';
import StatisticsBonusProgram from './screens/StatisticsBonusProgram';
import StatisticsMovies from './screens/StatisticsMovies';
import StatisticsUsage from './screens/StatisticsUsage';
import EditCinema from './screens/EditCinema';
import EditCinemaNew from './screens/EditCinemaNew/EditCinemaNew';
import EditSoonInCinema from './screens/EditSoonInCinema';
import EditLastChances from './screens/EditLastChances';
import EditAdminUsers from './screens/EditAdminUsers';
import EditBonusprogram from './screens/EditBonusprogram';
import PosTokens from './screens/PosTokens';
import Dashboard from './screens/Dashboard/Dashboard';
import EmailEditor from './screens/EmailEditor/EmailEditor';
import { MovieStats } from './screens/Dashboard/MovieStats/MovieStats';
import { CinemaComparison } from './screens/Dashboard/CinemaComparison/CinemaComparison';
import { brand, FEATURES } from './consts';
import TargetGroups from './screens/TargetGroups/TargetGroups';
import EditTargetGroup from './screens/EditTargetGroup/EditTargetGroup';
import EditUsers from './screens/EditUsers/EditUsers';
import EditUser from './screens/EditUser/EditUser';
import CinfinityStats from './screens/CinfinityStats/CinfinityStats';
import PayoutOverview from './screens/PayoutOverview/PayoutOverview';
import CreateVoucher from './screens/Vouchers/CreateVoucher';
import VoucherDetails from './screens/Vouchers/VoucherDetails';
import ShopifyOrders from './screens/Vouchers/ShopifyOrders';
import BeaconDetails from './screens/Beacons/BeaconDetails';
import EditBeacon from './screens/Beacons/EditBeacon';
import { CompanyOverview } from './screens/EditCompany/CompanyOverview';
import { EditCompany } from './screens/EditCompany/CompanyEdit';
import { MissedPaymentsOverview } from './screens/MissedPaymentsOverview';
import { Privileges } from './utils/user';
import { CinemaOperatingCompaniesOverview } from './screens/CinemaOperatingCompany/overview';
import { CinemaOperatingCompanyEdit } from './screens/CinemaOperatingCompany/edit';

const Default = () => <h1>default</h1>;

/*
The routes array contains all routes available. Not every user has access to all routes.

To add a new route:
- Add it to the routes array
- If the route should not be accessible by all users (protected):
	- check if the section of the route (e.g. marketing) is already in the protected paths array in hasPrivilegesForPath()
	- add logic to hasPrivilegesForPath to distinguish users
*/
export const routes = [
	// campaigning
	{
		label: 'Marketing',
		iconName: 'marketing',
		path: '/marketing',
		Component: Default,
		exact: true,
		requiredPrivileges: ['accessRightCampaigns'],
	},
	...(FEATURES.includes('NEW_CAMPAIGNING')
		? [
				{
					label: 'Kampagnen',
					path: '/marketing/campaigns',
					routeName: 'campaign-list',
					exact: true,
					Component: CampaignsNew,
					requiredPrivileges: ['accessRightCampaigns'],
				},
				{
					path: '/marketing/campaigns/:campaignId/edit',
					routeName: 'campaign-detail',
					Component: EditCampaignNew,
					requiredPrivileges: ['accessRightCampaigns'],
				},
				{
					path: '/marketing/campaigns/:campaignId/edit-email',
					routeName: 'emaileditor',
					Component: EmailEditor,
					requiredPrivileges: ['accessRightCampaigns'],
				},
		  ]
		: [
				{
					label: 'Kampagnen-Planer',
					path: '/marketing/campaigns',
					routeName: 'campaign-list',
					exact: true,
					Component: Campaigns,
					requiredPrivileges: ['accessRightCampaigns'],
				},
				{
					path: '/marketing/campaigns/new',
					routeName: 'campaign-new',
					Component: CreateCampaign,
					requiredPrivileges: ['accessRightCampaigns'],
				},
				{
					path: '/marketing/campaigns/:id/edit',
					routeName: 'campaign-detail',
					Component: EditCampaignOld,
					requiredPrivileges: ['accessRightCampaigns'],
				},
		  ]),
	...(FEATURES.includes('TARGETGROUP_EDIT')
		? [
				{
					label: 'Zielgruppen',
					path: '/marketing/targetgroups',
					routeName: 'target-group-overview',
					exact: true,
					Component: TargetGroups,
					requiredPrivileges: ['accessRightCampaigns'],
				},
				{
					path: '/marketing/targetgroups/:targetGroupId/edit',
					routeName: 'target-group-detail',
					Component: EditTargetGroup,
					requiredPrivileges: ['accessRightCampaigns'],
				},
		  ]
		: []),
	// analysis
	{
		label: 'Analyse',
		iconName: 'graph',
		path: '/analytics',
		Component: Default,
		exact: true,
		requiredPrivileges: ['accessRightDashboard'],
	},
	...(FEATURES.includes('CINFINITY_STATS')
		? [
				{
					label: 'Abo Statistiken',
					path: '/analytics/cinfinity',
					routeName: 'cinfinity-stats',
					Component: CinfinityStats,
					requiredPrivileges: ['accessRightFilmStatistics'],
				},
		  ]
		: []),
	...(FEATURES.includes('PAYOUT_OVERVIEW')
		? [
				{
					label: 'Auszahlungen',
					path: '/analytics/payouts',
					routeName: 'payout-overview',
					Component: PayoutOverview,
					exact: true,
					requiredPrivileges: ['accessRightDashboard'],
				},
				{
					path: '/analytics/missed-payments',
					label: 'Mahnwesen',
					routeName: 'MissedPayments',
					Component: MissedPaymentsOverview,
					requiredPrivileges: ['adminRole'],
				},
		  ]
		: []),
	...(FEATURES.includes('NEW_STATISTICS')
		? [
				{ path: '/', redirect: '/analytics/dashboard', requiredPrivileges: [] },
				{
					label: 'Dashboard',
					path: '/analytics/dashboard',
					routeName: 'dashboard',
					Component: Dashboard,
					requiredPrivileges: ['accessRightFilmStatistics'],
				},
				{
					label: 'Kino-Vergleich',
					path: '/analytics/cinemacomparison',
					routeName: 'cinemacomparison',
					Component: CinemaComparison,
					requiredPrivileges: ['accessRightFilmStatistics'],
				},
				{
					label: 'Filme',
					path: '/analytics/moviestats',
					routeName: 'moviestats',
					Component: MovieStats,
					requiredPrivileges: ['accessRightFilmStatistics'],
				},
		  ]
		: [
				{ path: '/', redirect: '/analytics/app', requiredPrivileges: [] },
				FEATURES.includes('BONUSPROGRAM')
					? {
							label: 'Bonusprogramm',
							path: '/analytics/bonusprogram',
							routeName: 'bonusprogram-stats',
							Component: StatisticsBonusProgram,
							requiredPrivileges: ['accessRightBonusProgram'],
					  }
					: null,

				{
					label: 'App-Nutzung',
					path: '/analytics/app',
					routeName: 'app-stats',
					Component: StatisticsUsage,
					requiredPrivileges: ['accessRightDashboard'],
				},
				{
					label: 'Filme',
					path: '/analytics/movies',
					routeName: 'movie-stats',
					Component: StatisticsMovies,
					requiredPrivileges: ['accessRightFilmStatistics'],
				},
		  ]),
	// editorial
	{
		label: 'Redaktion',
		iconName: 'pen',
		path: '/content',
		Component: Default,
		exact: true,
		requiredPrivileges: ['accessRightDashboard'],
	},
	FEATURES.includes('NEW_EDIT_CINEMA')
		? {
				label: 'Kino',
				path: '/content/cinema-new',
				routeName: 'cinema-editor-new',
				Component: EditCinemaNew,
				requiredPrivileges: ['accessRightDashboard'],
		  }
		: {
				label: 'Kino',
				path: '/content/cinema',
				routeName: 'cinema-editor',
				Component: EditCinema,
				requiredPrivileges: ['rootRole'],
		  },
	{
		label: 'Kinobetreiber',
		path: '/content/cinema-companies',
		routeName: 'CinemaOperatingCompaniesTable',
		Component: CinemaOperatingCompaniesOverview,
		requiredPrivileges: ['adminRole'],
	},
	{
		path: '/cinema-company/create',
		routeName: 'CinemaOperatingCompanyCreate',
		Component: CinemaOperatingCompanyEdit,
		requiredPrivileges: ['adminRole'],
	},
	{
		path: '/cinema-company/details/:id',
		routeName: 'CinemaOperatingCompanyEdit',
		Component: CinemaOperatingCompanyEdit,
		requiredPrivileges: ['adminRole'],
	},
	{
		label: 'Vorschau',
		path: '/content/soon',
		routeName: 'starting-soon-editor',
		Component: EditSoonInCinema,
		requiredPrivileges: ['accessRightDashboard'],
	},
	{
		label: 'Letzte Chance',
		path: '/content/lastChances',
		routeName: 'last-chance-editor',
		Component: EditLastChances,
		requiredPrivileges: ['accessRightDashboard'],
	},
	FEATURES.includes('BONUSPROGRAM')
		? {
				label: 'Bonusprogramm',
				path: '/content/bonusprogram',
				routeName: 'bonusprogram-editor',
				Component: EditBonusprogram,
				requiredPrivileges: ['rootRole'],
		  }
		: null,

	// Users
	...(FEATURES.includes('USER_EDIT')
		? [
				{
					label: 'Nutzer',
					iconName: 'profile',
					path: '/users',
					Component: Default,
					exact: true,
				},
				{
					label: 'Nutzer verwalten',
					path: '/users/overview',
					Component: EditUsers,
					exact: true,
					requiredPrivileges: ['supportRole'],
				},

				{
					path: '/users/details/:id',
					routeName: 'user-edit',
					Component: EditUser,
				},
		  ]
		: [
				{
					label: 'Berechtigungen',
					path: '/admin/editadminusers',
					routeName: 'user-privileges',
					Component: EditAdminUsers,
					requiredPrivileges: ['rootRole'],
				},
		  ]),

	//companies
	...(brand === 'CINFINITY' || brand === 'CINFINITY-WEB'
		? [
				{
					path: '/admin/company/create',
					Component: EditCompany,
					requiredPrivileges: ['adminRole'],
				},
				{
					path: '/admin/company/edit/:id',
					Component: EditCompany,
					requiredPrivileges: ['adminRole'],
				},
				{
					path: '/admin/companies',
					label: 'Unternehmen bearbeiten',
					exact: true,
					Component: CompanyOverview,
					requiredPrivileges: ['adminRole'],
				},
		  ]
		: []),

	// Gutscheine
	...(brand === 'CINFINITY' || brand === 'CINFINITY-WEB'
		? [
				{
					label: 'Gutscheine',
					iconName: 'pen',
					path: '/gutscheine',
					Component: Default,
					exact: true,
				},
				{
					label: 'Gutscheine erstellen',
					path: '/gutscheine/erstellen',
					Component: CreateVoucher,
					exact: true,
					requiredPrivileges: ['adminRole'],
				},
				{
					label: 'Gutscheindetails',
					path: '/gutscheine/details',
					Component: VoucherDetails,
					exact: true,
					requiredPrivileges: ['adminRole'],
				},
				{
					label: 'Shopify bestellungen',
					path: '/gutscheine/shopify',
					Component: ShopifyOrders,
					exact: true,
					requiredPrivileges: ['adminRole'],
				},
		  ]
		: []),
	//Admin
	FEATURES.includes('BONUSPROGRAM') ||
	!FEATURES.includes('USER_EDIT') ||
	brand === 'CINFINITY' ||
	brand === 'CINFINITY-WEB'
		? {
				label: 'Admin',
				iconName: 'gear',
				path: '/admin',
				Component: Default,
				exact: true,
				requiredPrivileges: ['accessRightBonusProgram', 'rootRole', 'adminRole'],
		  }
		: null,

	FEATURES.includes('BONUSPROGRAM')
		? {
				label: 'Cinuru-POS',
				path: '/admin/postokens',
				routeName: 'postokens',
				Component: PosTokens,
				requiredPrivileges: ['accessRightBonusProgram'],
		  }
		: null,

	...(brand === 'CINFINITY' || brand === 'CINFINITY-WEB'
		? [
				{
					label: 'Beacons',
					path: '/admin/beacons',
					routeName: 'beacon-editor',
					Component: BeaconDetails,
					exact: true,
					requiredPrivileges: ['rootRole', 'adminRole'],
				},

				{
					path: '/admin/beacons/new',
					routeName: 'beacon-create',
					Component: EditBeacon,
					requiredPrivileges: ['rootRole', 'adminRole'],
				},
				{
					path: '/admin/beacons/details/:id',
					routeName: 'beacon-edit',
					Component: EditBeacon,
					requiredPrivileges: ['rootRole', 'adminRole'],
				},
		  ]
		: []),
].filter((el) => el);

// Checks for one path if the current user has access rights
export const hasPrivilegesForPath = (route, userPrivileges: Privileges): boolean => {
	if (userPrivileges.adminForCinemas.length === 0) return false;
	if (userPrivileges.rootRole) return true;

	if (!route.requiredPrivileges || route.requiredPrivileges.length === 0) return true;
	return route.requiredPrivileges.some((requiredPriv) => userPrivileges[requiredPriv]);
};

// generate sidebar routes tree, with top level sections and nested sub items
export const getNavTreeForPrivileges = (privileges) => {
	const sections = routes
		//remove all non-top-level items
		.filter(({ path, redirect }) => !redirect && path.split('/').length === 2)
		//add all 2nd level items to their section
		.map((section) => ({
			...section,
			items: routes
				//remove all non 2nd level items
				.filter(({ path, redirect }) => !redirect && path.split('/').length === 3)
				// filter out the routes which the user doesn't have privileges for
				.filter((route) => hasPrivilegesForPath(route, privileges))
				.filter(
					(route) =>
						route.path !== section.path &&
						route.path.includes(section.path) &&
						hasPrivilegesForPath(route, privileges)
				),
		}))
		.filter((section) => section.items.length > 0); // Filter out all empty sections;

	return sections;
};
