import React, { useMemo } from 'react';
import StickyHeaderWrapper, { StickyHeaderButton } from '../../components/StickyHeaderWrapper';
import Table, { RowItem } from '../../components/Table';
import { mapColumns } from '../../utils';
import { useHistory } from 'react-router';
import { useUserPrivileges } from '../../utils/user';

const cinemaCompaniesColumns = mapColumns([
	'Name',
	'Benutzer',
	'Zugriff auf das Dashboard',
	'Filmstatistiken',
	'Zugriffsrecht Bonusprogramm',
	'Zugriff auf Campaigning-Tools',
]);

export function CinemaOperatingCompaniesOverview() {
	const privileges = useUserPrivileges();

	const cinemaOperatingCompanies = privileges?.belongsToCinemaOperatingCompanies || [];

	const history = useHistory();

	const rows = useMemo<RowItem[]>(
		() =>
			cinemaOperatingCompanies.map((c) => ({
				id: c.id,
				data: [
					{ text: c.name },
					{
						text: c.associatedUsers?.length
							? c.associatedUsers.map((c) => c.fullName || c.email).join(', ')
							: '-',
					},
					{ text: (!!c.accessRightDashboard).toString() },
					{ text: (!!c.accessRightFilmStatistics).toString() },
					{ text: (!!c.accessRightBonusProgram).toString() },
					{ text: (!!c.accessRightCampaigning).toString() },
					{
						buttonLabel: 'Bearbeiten',
						onPress: () => history.push(`/cinema-company/details/${c.id}`),
					},
				],
				rawData: c,
			})),
		[cinemaOperatingCompanies]
	);

	const buttons = React.useMemo(
		() => [
			{
				label: 'Kinobetreiber erstellen',
				onClick: () => history.push('/cinema-company/create'),
				startIconName: 'Add',
				loading: false,
				collapsedIconName: 'Add',
			} as StickyHeaderButton,
		],
		[]
	);

	return (
		<StickyHeaderWrapper
			label="Kinobetreiber"
			maxContentWidth="130rem"
			isLoading={!privileges}
			buttons={buttons}
		>
			<Table
				defaultRowsPerPage={10}
				columns={cinemaCompaniesColumns}
				allRows={rows}
				isSearchable={true}
				isSelectable={false}
			/>
		</StickyHeaderWrapper>
	);
}
