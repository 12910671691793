import React, { useEffect, useMemo, useState } from 'react';
import StickyHeaderWrapper, { StickyHeaderButton } from '../../components/StickyHeaderWrapper';
import ContentWrapper from '../../components/ContentWrapper';
import Txt from '../../components/Txt';
import TextField from '../../components/TextField';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-apollo';
import { useUserPrivileges } from '../../utils/user';
import {
	AllCinemasQuery,
	CinemaOperatingCompanyInput,
	CreateCinemaOperatingCompany,
	UpdateCinemaOperatingCompany,
} from './queries';
import { Box, FormControlLabel } from '@mui/material';
import Checkbox from 'antd/lib/checkbox';
import styled from 'styled-components';
import SectionWrapper2 from '../../components/SectionWrapper2';
import SearchSelectField2 from '../../components/SearchSelectField2';
import { omit, xor } from 'lodash';
import { useSnackbar } from 'notistack';
import { brand } from '../../consts';

const FormControlLabelGapped = styled(FormControlLabel)`
	gap: 8px;
`;

export function CinemaOperatingCompanyEdit() {
	const { id } = useParams<{ id: string }>();
	const isEditing = !!id;
	const privileges = useUserPrivileges();

	const history = useHistory();

	const { enqueueSnackbar } = useSnackbar();
	const [cinemaOperatingCompanyInput, setCinemaOperatingCompanyInput] = useState<
		Partial<CinemaOperatingCompanyInput>
	>({});

	const onSave = () => {
		history.push('/content/cinema-companies');

		if (isEditing) {
			enqueueSnackbar('FTB bearbeitet', {
				variant: 'success',
			});
		} else {
			enqueueSnackbar('FTB erstellt', {
				variant: 'success',
			});
		}
	};

	const [createCinemaOperatingCompany, { called: calledSave, loading: loadingSave }] = useMutation(
		CreateCinemaOperatingCompany,
		{
			onCompleted: onSave,
			refetchQueries: ['Privileges'],
		}
	);
	const [
		updateCinemaOperatingCompany,
		{ called: calledUpdate, loading: loadingUpdate },
	] = useMutation(UpdateCinemaOperatingCompany, {
		onCompleted: onSave,
	});

	const hasTriedSaving = calledUpdate || calledSave;

	const loading = loadingSave || loadingUpdate;

	const existingCinemaOperatingCompany = useMemo(
		() =>
			isEditing
				? privileges?.belongsToCinemaOperatingCompanies?.find((c) => c.id === id)
				: undefined,
		[isEditing]
	);

	const { data: availableCinemaData, loading: loadingAvailableCinemas } = useQuery(
		AllCinemasQuery,
		{ fetchPolicy: 'cache-and-network' }
	);

	useEffect(() => {
		if (existingCinemaOperatingCompany) {
			setCinemaOperatingCompanyInput({
				...omit(existingCinemaOperatingCompany, ['id', '__typename', 'cinemas', 'associatedUsers']),
				cinemasIds: existingCinemaOperatingCompany.cinemas?.map((a) => a.id) || [],
			});
		}
	}, [!!existingCinemaOperatingCompany]);

	const shouldUpdate = useMemo(
		() =>
			!existingCinemaOperatingCompany ||
			!!xor(
				cinemaOperatingCompanyInput.cinemasIds,
				existingCinemaOperatingCompany.cinemas?.map((c) => c.id) || []
			).length ||
			cinemaOperatingCompanyInput.name != existingCinemaOperatingCompany.name ||
			cinemaOperatingCompanyInput.accessRightBonusProgram !=
				existingCinemaOperatingCompany.accessRightBonusProgram ||
			cinemaOperatingCompanyInput.accessRightCampaigning !=
				existingCinemaOperatingCompany.accessRightCampaigning ||
			cinemaOperatingCompanyInput.accessRightDashboard !=
				existingCinemaOperatingCompany.accessRightDashboard ||
			cinemaOperatingCompanyInput.accessRightFilmStatistics !=
				existingCinemaOperatingCompany.accessRightFilmStatistics,
		[existingCinemaOperatingCompany, cinemaOperatingCompanyInput]
	);

	const canSave = useMemo(() => {
		if (!cinemaOperatingCompanyInput.name) {
			return false;
		}

		if (existingCinemaOperatingCompany) {
			return shouldUpdate;
		}

		return true;
	}, [cinemaOperatingCompanyInput, existingCinemaOperatingCompany]);

	const handleSave = () => {
		if (existingCinemaOperatingCompany) {
			updateCinemaOperatingCompany({
				variables: {
					id,
					data: {
						...cinemaOperatingCompanyInput,
						id: undefined,
					},
				},
			});
		} else {
			createCinemaOperatingCompany({
				variables: {
					data: cinemaOperatingCompanyInput,
				},
			});
		}
	};

	const buttons = [
		{
			label: isEditing ? 'Unternehmen bearbeiten' : 'Unternehmen erstellen',
			onClick: handleSave,
			startIconName: isEditing ? 'EditRounded' : 'SaveOutlined',
			disabled: !canSave,
			loading: loadingSave || loadingUpdate,
			loadingText: 'Speichern...',
			collapsedIconName: isEditing ? 'EditRounded' : 'SaveOutlined',
		} as StickyHeaderButton,
	];

	return (
		<StickyHeaderWrapper
			showWarningOnLeave={shouldUpdate && !calledSave && !calledUpdate}
			warningOnLeave={
				'Das Unternehmen wurde bearbeitet aber Änderungen werden nicht automatisch gespeichert. Ohne speichern fortfahren?'
			}
			label="Unternehmen bearbeiten"
			isLoading={loading}
			maxContentWidth="130rem"
			buttons={buttons}
		>
			<ContentWrapper>
				<Box>
					<Txt variant="h6">FTB name</Txt>
					<TextField
						m="2rem 0"
						fullWidth
						variant="outlined"
						autoFocus
						error={hasTriedSaving && !cinemaOperatingCompanyInput.name}
						key={cinemaOperatingCompanyInput.name}
						defaultValue={cinemaOperatingCompanyInput.name}
						label={'Name des FTB'}
						onChange={(name) => setCinemaOperatingCompanyInput((input) => ({ ...input, name }))}
						disabled={loading}
					/>
				</Box>
				<Box display="flex" flexDirection={'column'} paddingLeft={'12px'} gap={'8px'}>
					<FormControlLabelGapped
						control={
							<Checkbox
								checked={cinemaOperatingCompanyInput.accessRightDashboard}
								onChange={() =>
									setCinemaOperatingCompanyInput((customer) => ({
										...customer,
										accessRightDashboard: !customer.accessRightDashboard,
									}))
								}
							/>
						}
						label={'Zugriff auf das Dashboard'}
					/>
					{brand !== 'CINFINITY' && (
						<FormControlLabelGapped
							control={
								<Checkbox
									checked={cinemaOperatingCompanyInput.accessRightBonusProgram}
									onChange={() =>
										setCinemaOperatingCompanyInput((customer) => ({
											...customer,
											accessRightBonusProgram: !customer.accessRightBonusProgram,
										}))
									}
								/>
							}
							label={'Zugriffsrecht Bonusprogramm'}
						/>
					)}
					<FormControlLabelGapped
						control={
							<Checkbox
								checked={cinemaOperatingCompanyInput.accessRightFilmStatistics}
								onChange={() =>
									setCinemaOperatingCompanyInput((customer) => ({
										...customer,
										accessRightFilmStatistics: !customer.accessRightFilmStatistics,
									}))
								}
							/>
						}
						label={'Filmstatistiken'}
					/>
					<FormControlLabelGapped
						control={
							<Checkbox
								checked={cinemaOperatingCompanyInput.accessRightCampaigning}
								onChange={() =>
									setCinemaOperatingCompanyInput((customer) => ({
										...customer,
										accessRightCampaigning: !customer.accessRightCampaigning,
									}))
								}
							/>
						}
						label={'Zugriff auf Campaigning-Tools'}
					/>
				</Box>
				<SectionWrapper2 label="Admin Rechte" p="2rem" m="2rem 0 0 0">
					<SearchSelectField2
						label="Kinos zuweisen"
						options={availableCinemaData?.cinemas || []}
						labelKey="name"
						valueKey="id"
						m="1rem 0 0 0"
						onChange={(opts) =>
							setCinemaOperatingCompanyInput((customer) => ({
								...customer,
								cinemasIds: opts.map((o) => o.value),
							}))
						}
						key={JSON.stringify(cinemaOperatingCompanyInput.cinemasIds)}
						defaultValues={cinemaOperatingCompanyInput.cinemasIds}
						multiple
					/>
				</SectionWrapper2>
			</ContentWrapper>
		</StickyHeaderWrapper>
	);
}
